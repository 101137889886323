<template>
  <div class="Header">
    <div class="Header-top">
      <Breadcrumb></Breadcrumb>
      <div>
        <button class="btn" @click="logout()">
          Logout
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
    <div class="Header-bottom">
      <Breadcrumbs></Breadcrumbs>
    </div>
  </div>
</template>

<script>
import { removeToken } from "@/utils/auth"; //清楚token
import Breadcrumb from "@/components/Breadcrumb";
import Breadcrumbs from "@/components/Breadcrumbs";
export default {
  name: "Header",
  components: { Breadcrumb, Breadcrumbs },
  data() {
    return {};
  },
  created() { },
  mounted() { },
  methods: {
    logout() {
      removeToken();
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
/* @import url(@/assets/css/color.css); */

.Header-top {
  height: 50px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  /* background: #edc4c4; */
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.Header-bottom {
  display: flex;
  align-items: center;
  height: 28px;
  width: 100%;
  padding: 1px 10px;
  background: #fff;
  border-bottom: 1px solid #d8dce5;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
    0 0 3px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
}

/* 炫酷按钮 */
.btn {
  padding: 10px 20px;
  color: #4f5469;
  position: relative;
  right: 20%;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  /* right: 10px; */
}

.btn:hover {
  border-radius: 5px;
  color: #fff;
  background: #4f5469;
  /* background: #03e9f4; */
  box-shadow: 0 0 5px 0 #4f5469, 0 0 25px 0 #4f5469, 0 0 50px 0 #4f5469,
    0 0 100px 0 #4f5469;
  transition: all 1s linear;
}

.btn>span {
  position: absolute;
}

.btn>span:nth-child(1) {
  width: 100%;
  height: 3px;
  background: -webkit-linear-gradient(left, transparent, #4f5469);
  left: -100%;
  top: 0px;
  animation: line1 1s linear infinite;
}

@keyframes line1 {

  50%,
  100% {
    left: 100%;
  }
}

.btn>span:nth-child(2) {
  width: 3px;
  height: 100%;
  background: -webkit-linear-gradient(top, transparent, #4f5469);
  right: 0px;
  top: -100%;
  animation: line2 1s 0.25s linear infinite;
}

@keyframes line2 {

  50%,
  100% {
    top: 100%;
  }
}

.btn>span:nth-child(3) {
  width: 100%;
  height: 3px;
  background: -webkit-linear-gradient(left, #4f5469, transparent);
  left: 100%;
  bottom: 0px;
  animation: line3 1s 0.75s linear infinite;
}

@keyframes line3 {

  50%,
  100% {
    left: -100%;
  }
}

.btn>span:nth-child(4) {
  width: 3px;
  height: 100%;
  background: -webkit-linear-gradient(top, transparent, #4f5469);
  left: 0px;
  top: 100%;
  animation: line4 1s 1s linear infinite;
}

@keyframes line4 {

  50%,
  100% {
    top: -100%;
  }
}
</style>