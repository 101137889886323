<template>
  <div class="sideNav">
    <div class="sideNav-top">
      <img src="~@/assets/images/pay.png" alt="" class="img" />
      <span class="text">Oceanpay</span>
    </div>
    <div class="sideNav-list">
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <el-menu
          :router="true"
          :default-active="index"
          :collapse="false"
          background-color="rgba(255,255,255,0)"
          active-text-color="#6a9aff"
          text-color="#eee"
          :unique-opened="true"
          :collapse-transition="true"
          mode="vertical"
          @select="handleSelect"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-menu-item index="/home">
            <i class="el-icon-house"></i>
            <span slot="title">首页</span>
          </el-menu-item>

          <el-menu-item index="/message" v-if="userRole === 'admin'">
            <i class="el-icon-user"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>

          <el-menu-item index="/roleManagement" v-if="userRole === 'admin'">
            <i class="el-icon-user-solid"></i>
            <span slot="title">角色管理</span>
          </el-menu-item>

          <el-submenu index="/merchantManagement">
            <template slot="title">
              <i class="el-icon-s-shop"></i>
              <span>商户管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                index="/merchantManagement/merchantInfo"
                v-if="userRole === 'admin' || userRole === 'manager'"
              >
                <i class="el-icon-s-custom"></i>
                <span slot="title">商户信息</span>
              </el-menu-item>
              <el-menu-item index="/merchantManagement/merchantAppInfo">
                <i class="el-icon-menu"></i>
                <span slot="title">应用管理</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu
            index="/payManagement"
            v-if="userRole === 'admin' || userRole === 'manager'"
          >
            <template slot="title">
              <i class="el-icon-connection"></i>
              <span>支付配置</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/payManagement/payInterfaceInfo">
                <i class="el-icon-set-up"></i>
                <span slot="title">支付接口</span>
              </el-menu-item>
              <el-menu-item index="/payManagement/payWayInfo">
                <i class="el-icon-s-claim"></i>
                <span slot="title">支付方式</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="/orderManagement">
            <template slot="title">
              <i class="el-icon-money"></i>
              <span>订单管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/orderManagement/payOrderInfo">
                <i class="el-icon-document-checked"></i>
                <span slot="title">支付订单</span>
              </el-menu-item>
              <el-menu-item index="/orderManagement/refundOrderInfo">
                <i class="el-icon-document-delete"></i>
                <span slot="title">退款订单</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-menu-item index="/staticCodeManager">
            <i class="el-icon-s-help"></i>
            <span slot="title">静态码管理</span>
          </el-menu-item>

          <el-menu-item index="/payTest">
            <i class="el-icon-circle-check"></i>
            <span slot="title">支付测试</span>
          </el-menu-item>

          <el-submenu index="/document">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>开发文档</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/document/accessGuide">
                <i class="el-icon-guide"></i>
                <span slot="title">接入指引</span>
              </el-menu-item>

              <el-menu-item index="/document/sampleCode">
                <i class="el-icon-info"></i>
                <span slot="title">示例代码</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //当前索引
      index: "",
      //管理权限
      userRole: "",
    };
  },
  mounted() {
    this.getUserRole();
    this.getPath();
  },

  methods: {
    //获取路由
    getPath() {
      const { meta, path } = this.$route;
      // console.log(meta, path);
      if (meta.activeMenu) {
        this.index = meta.activeMenu;
      } else {
        this.index = path;
      }
    },

    //获取权限
    getUserRole() {
      const userRole = localStorage.getItem("userRole");
      console.log("sideNav页面：", userRole);
      if (userRole.includes("admin")) {
        //超级管理员
        this.userRole = "admin";
      } else if (userRole.includes("manager")) {
        //普通管理员
        this.userRole = "manager";
      } else if (userRole.includes("merchant")) {
        //商户
        this.userRole = "merchant";
      } else {
        console.log(this.userRole);
      }
    },

    //菜单激活回调
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },

    //sub-menu 展开的回调
    handleOpen(key, keyPath) {
      // console.log("打开");
      // console.log(key, keyPath);
    },

    //sub-menu 关闭的回调
    handleClose(key, keyPath) {
      // console.log("收起");
      // console.log(key, keyPath);
    },
  },
  watch: {
    "$route.path"() {
      this.getPath();
    },
  },
  computed: {},
};
</script>

<style scoped lang="less">
/*删除多余分组占位*/
:deep .el-menu-item-group__title {
  display: none;
}

:deep .el-menu-item:hover {
  background-color: #a1a6bb !important;
  color: #000 !important;
}

:deep .el-submenu__title:hover {
  background-color: #a1a6bb !important;
  color: #000 !important;
}

.sideNav {
  transition: width 0.28s;
  width: 200px;
  background-color: #44495a;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
  font-weight: 100;
  text-rendering: optimizeLegibility;

  .sideNav-top {
    padding: 30px 5px 30px 0;
    text-align: center;
    font-size: 15px;

    .img {
      width: 30px;
      margin-right: 8px;
      margin-bottom: 5px;
    }

    .text {
      font-size: 22px;
      font-weight: 600;
      vertical-align: bottom;
      color: #eee;
    }
  }

  .sideNav-list {
    .el-menu {
      border: 0;
      background-color: rgba(255, 255, 255, 0);
    }
  }
}
</style>
